import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 19 19">
    <path
      fill={color}
      d="M9.5,15.1c3.1,0,5.6-2.5,5.6-5.6c0-3.1-2.5-5.6-5.6-5.6c-3.1,0-5.6,2.5-5.6,5.6C3.9,12.6,6.4,15.1,9.5,15.1z M9.5,4.6
	c2.7,0,4.9,2.2,4.9,4.9c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9C4.6,6.8,6.8,4.6,9.5,4.6z M9.5,3.1c0.2,0,0.3-0.1,0.3-0.3V0.3
	C9.8,0.1,9.7,0,9.5,0C9.3,0,9.2,0.1,9.2,0.3v2.5C9.2,3,9.3,3.1,9.5,3.1z M9.8,18.7v-2.5c0-0.2-0.1-0.3-0.3-0.3
	c-0.2,0-0.3,0.1-0.3,0.3v2.5c0,0.2,0.1,0.3,0.3,0.3C9.7,19,9.8,18.9,9.8,18.7z M0.3,9.8h2.5c0.2,0,0.3-0.1,0.3-0.3
	c0-0.2-0.1-0.3-0.3-0.3H0.3C0.1,9.2,0,9.3,0,9.5C0,9.7,0.1,9.8,0.3,9.8z M18.7,9.2h-2.5c-0.2,0-0.3,0.1-0.3,0.3
	c0,0.2,0.1,0.3,0.3,0.3h2.5c0.2,0,0.3-0.1,0.3-0.3C19,9.3,18.9,9.2,18.7,9.2z M3.2,16.2L5,14.4c0.1-0.1,0.1-0.3,0-0.5
	c-0.1-0.1-0.3-0.1-0.5,0l-1.8,1.8c-0.1,0.1-0.1,0.3,0,0.5C2.8,16.4,3,16.4,3.2,16.2z M14.2,5.1c0.1,0,0.2-0.1,0.3-0.1l1.8-1.8
	c0.1-0.1,0.1-0.3,0-0.5c-0.1-0.2-0.4-0.2-0.5-0.1L14,4.6c-0.1,0.1-0.1,0.3,0,0.5C14,5.1,14.2,5.1,14.2,5.1z M14.4,14
	c-0.1-0.1-0.3-0.1-0.5,0c-0.1,0.1-0.1,0.3,0,0.5l1.8,1.8c0.1,0.1,0.3,0.1,0.5,0c0.1-0.1,0.1-0.3,0-0.5L14.4,14z M4.6,5
	C4.7,5.2,4.9,5.2,5,5c0.1-0.1,0.1-0.3,0-0.5L3.2,2.8c-0.1-0.1-0.3-0.1-0.5,0c-0.1,0.1-0.1,0.4,0,0.5L4.6,5z"
    />
  </svg>
);
